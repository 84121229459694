import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { Apiurl } from 'app/apiconfig';

const baseQuery = fetchBaseQuery({ baseUrl: Apiurl[0].api_url });

const rootApi = createApi({
  baseQuery,
  tagTypes: [
    'Dreams', // url: /dreams/list
    'DreamCategories', // url: /categories work-in
    'Goals', // url: /goals
    'Tasks', // url:
    'DashboardData', // url: /get-dashboard-data work-in tasks
    'GlobalScore', // url: /global-score
    'Awards', // url: /get-awards
    'Rewards', // url: /get-rewards
    'DashboardCalanderTasks', // url: /dashboard-calender-tasks  work-in tasks also
    'AllTasksLogs', // url:
    'DashboardTasksCountsData', // url: /dashboard-tasks-count work-in tasks
    'SubuserCompletedTaskCountData', // url: /dashboard-subusers-count work-in
    'Subusers', // url: /get-subusers
    'DashboardNetworthsCount', // url: /dashboard-networths-count
    'Networths', // url: /networths
    'DailyStatusData', // url: /user/daily-report
    'DailyPerformanceReports', // url: /daily-performances-reports work-in
    'NetwothCategories', // url: /networths-chart-filter
    'NetworthsChartFilter', // url: /networths-chart-filter
    'NetworthsBucketList', // url: /networth-buckets
    'User', // url: /get-user
    'TimezoneList', // url: /timezones/list work-in
    'UserBillingData', // url: /user/billing
    'TaskTags', // url: /tags
    'CustomTaskStatusList', // url: /grid-status-list
    'CustomTaskStatusPositions', // url: /grid-status-positions
    'SlackData', // url: /slack/data
    'SlackSettings', // url: /user/get-slack-setting
    'Tickets', // url: /tickets
    'TicketsReplies', // url: /ticket-replies
    'FilteredGoals', // url: /filter-goals
    'SubscriptionPlansList' // url: /plans/list
  ],
  endpoints: (builder) => ({})
});

export default rootApi;
